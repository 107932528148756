<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 중장비구분 -->
          <c-select
            type="search"
            codeGroupCd="HHM_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            name="machineryClassification"
            label="중장비구분"
            v-model="searchParam.machineryClassification"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 지급일 -->
          <c-datepicker
            :range="true"
            name="period"
            label="출입기간"
            defaultStart="-6M"
            defaultEnd="6M"
            v-model="searchParam.period"
          ></c-datepicker>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="회사 출입 중장비 목록"
      tableId="table"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group outline>
            <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="openPop" />
            <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </div>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'entranceHeavyEquipment',
  data() {
    return {
      searchParam: {
        plantCd: null,
        period: [],
        deptCd: '',
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'col1',
            field: 'col1',
            label: '중장비구분',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'col9',
            field: 'col9',
            label: '차량번호',
            style: 'width:300px',
            align: 'left',
            sortable: true,
            type: 'link'
          },
          {
            name: 'col2',
            field: 'col2',
            label: '출입기간',
            style: 'width:200px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'col3',
            field: 'col3',
            label: '관련작업',
            style: 'width:200px',
            align: 'left',
            sortable: true,
          },
          {
            name: 'col5',
            field: 'col5',
            label: '장비업체명',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'col8',
            field: 'col8',
            label: '운전자명',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'col6',
            field: 'col6',
            label: 'LBLMANAGER',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        width: '70%',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.swp.workPermit.list.url
      // code setting
      // list setting
      this.getList();
    },
    /* eslint-disable no-unused-vars */
    getList() {
      // this.$http.url = this.listUrl;
      // this.$http.type = 'GET';
      // this.$http.param = this.searchParam;
      // this.$http.request((_result) => {
      //   this.grid.data = _result.data;
      // },);
      this.grid.data = [
        {
          plantName: '사업장1',
          col1: '포크레인',
          col9: '포크레인 ********',
          col2: '2022-12-22 ~ 2022-12-31',
          col3: '공사1',
          col4: '공사업체1',
          col5: '장비업체1',
          col6: '김길동',
          col7: '안전환경팀',
          col8: '홍길동',
          col10: '위치1'
        },
        {
          plantName: '사업장1',
          col1: '중장비',
          col9: '중장비 ********',
          col2: '2022-12-22 ~ 2023-04-31',
          col3: '공사2',
          col4: '공사업체2',
          col5: '장비업체2',
          col6: '홍이두',
          col7: '안전환경팀',
          col8: '이민수',
          col10: '위치2'
        },
        {
          plantName: '사업장1',
          col1: '크레인',
          col9: '크레인 *******',
          col2: '2022-02-22 ~ 2022-12-31',
          col3: '공사3',
          col4: '공사업체3',
          col5: '장비업체3',
          col6: '장정현',
          col7: '안전환경팀',
          col8: '유정무',
          col10: '위치3'
        },
        {
          plantName: '사업장1',
          col1: '화물차량',
          col9: '화물차량 *******',
          col2: '2022-12-22 ~ 2022-12-31',
          col3: '공사4',
          col4: '공사업체4',
          col5: '장비업체4',
          col6: '장무곤',
          col7: '안전환경팀',
          col8: '이기석',
          col10: '위치4'
        },
        {
          plantName: '사업장1',
          col1: '트레일러',
          col9: '트레일러 *******',
          col2: '2022-05-10 ~ 2022-08-31',
          col3: '공사5',
          col4: '공사업체5',
          col5: '장비업체5',
          col6: '이동현',
          col7: '안전환경팀',
          col8: '문지연',
          col10: '위치5'
        },
        
      ]
    },
    openPop() {
      this.linkClick();
    },
    linkClick(row, col, index) {
      this.popupOptions.title = '중장비 출입 상세'; // 
      this.popupOptions.param = {
        id: row ? row.id : null
      };
      this.popupOptions.target = () => import(`${'./entranceHeavyEquipmentDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  },
};
</script>
